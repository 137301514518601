import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

function SEO({ description, meta, title }: { description?: string; meta?: { [key: string]: string; }; title?: string; }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  const metaArray: Array<{ content: string; property: string; }> = Object.entries({
    description: metaDescription,
    "og:description": metaDescription,
    "og:title": title,
    "og:type": "website",
    "twitter:card": "summary",
    "twitter:creator": site.siteMetadata.author,
    "twitter:description": metaDescription,
    "twitter:title": title,
    ...meta,
  }).map((e: any) => ({ content: e[1], property: e[0] }));

  return (
    <Helmet>
      <meta name="description" content={metaDescription} />
      <title>{`${title} | ${site.siteMetadata.title}`}</title>
      {metaArray.map((e: { content: string; property: string; }, i: number) => (
        <meta {...e} key={i} />
      ))}
    </Helmet>
  );
}

export default SEO;
